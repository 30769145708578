import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')


const LinkContent = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.h1`
  width: 100%;
  padding: 0 15px 0;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  text-align: left;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.tertiary};

  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: auto;

  @media screen and (max-width: ${props => props.theme.breakpoint.small}px) {
    line-height: 20px;
    font-size: 14px;
    letter-spacing: 0.43px;
    padding-left: 1em;
    padding-right: 1em;

    &.subtitle {
      font-size: 24px; 
      line-height: 125%;
    }
  }
`

const Wrapper = styled.div`
  padding: 0 25px 50px;
  & a {
    text-decoration: none;
  }
`

const LinkBack = props => {
  // const maxWidthSlider = (slidesRowlength * 347) + ((slidesRowlength - 1) * 30 )

  return (
    <Wrapper>
      <Link to={props.linkTo || '#'}>
        <LinkContent>
          <img 
            src="/icons/chevron-left.svg"
            alt={props.alt || 'Back'}
            style={{ maxWidth: '7px' }}/>
          <Title>
            {props.title}
          </Title>
        </LinkContent>
      </Link>
    </Wrapper>
  )
}

export default LinkBack
