import React from 'react'
import styled from 'styled-components'

require('slick-carousel/slick/slick.css')
require('slick-carousel/slick/slick-theme.css')


const Wrapper = styled.div`
  padding: 0 0 120px;
  margin: 0 auto;

  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 50px;
  letter-spacing: 0.05em;

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    font-size: 18px;
    letter-spacing: 0.05em;
    color: ${props => props.theme.colors.black};
  }

  .row {
    margin-bottom: 30px;
  }

  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 25px;
    padding-left: 25px;
  }

  blockquote.blockquote {
    padding: 120px 0;

    & p {
      font-family: Raleway;
      font-style: italic;
      font-weight: 300;
      font-size: 35px;
      line-height: 41px;
      letter-spacing: 0.05em;
    }

    & .blockquote-footer {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 50px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    & .blockquote-footer {
      margin: 23px 0 0 50px;
    }

    & .blockquote-footer::before {
      content: '- \\00A0';
    }
    
  }

  & .gatsby-resp-image-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: none !important;
  }

  & h1 {
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.239186px;
  }
  & h2 {
    font-size: 24px;
    line-height: 125%;
    letter-spacing: 0.06px;
  }
  & h3 {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.0727273px;
  }
  & h4 {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0727273px;
  }
  & h5 {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.0456169px;
  }

  & ul {
    list-style: disc;

    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 50px;

    // color: ${props => props.theme.colors.tertiary};
    margin-left: 20px;

  }

  & ul li {
    list-style: disc;
  }

  a {
    color: ${props => props.theme.colors.tertiary};
  }

  & strong {
    font-weight: bold;
  }

  & em {
    font-style: italic;
  }

  
  @media screen and (max-width: 600px) {
    p {
      font-size: 16px;
      line-height: 19px;
    }
    .col-md-6 {
      margin-bottom: 30px;
    }
  }

`

const PostBody = props => {
  // const maxWidthSlider = (slidesRowlength * 347) + ((slidesRowlength - 1) * 30 )

  const body = props.body.childMarkdownRemark.html

  return (
    <div className="container-fluid" style={{ paddingLeft: '25px', paddingRight: '25px'}}>
      <div className="row">
        <div className="col-md-12">
          <Wrapper
            dangerouslySetInnerHTML={{
              __html: body
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default PostBody
